import Vue from "vue";

const getResponseError = errorResponse => {
  console.log(errorResponse);
  if (errorResponse.body.error) {
    return errorResponse.body.error;
  } else {
    return `${errorResponse.statusText} (${errorResponse.status})`;
  }
};

export const echo = modality => {
  return new Promise((resolve, reject) => {
    Vue.$http
      .get(`api/pacs/echo/${modality.tag}`)
      .then(() => resolve())
      .catch(error => reject(getResponseError(error)));
  });
};

export const getModalities = () => {
  return new Promise((resolve, reject) => {
    Vue.$http
      .get(`api/pacs/modalities/`)
      .then(resp => resolve(resp))
      .catch(error => reject(getResponseError(error)));
  });
};

export const query = (modality, queryParams = {}) => {
  queryParams = Object.keys(queryParams).reduce((result, key) => {
    let value;
    switch (key) {
      // convert dates to “yyyymmdd”
      case "PatientBirthDate":
        value = queryParams[key]?.replaceAll("-", "");
        break;

      // convert dates to “hhmm”
      case "StudyTime":
        value = queryParams[key]?.replaceAll(":", "");
        break;

      // convert date ranges to “yyyymmdd-yyyymmdd”
      case "StudyDate":
        if (queryParams[key] && queryParams[key].length) {
          const d1 = queryParams[key][1]?.replaceAll("-", "");
          const d2 = queryParams[key][2]?.replaceAll("-", "");

          value = d1 && d2 ? `${d2}-${d1}` : null;
        }
        break;

      default:
        value = queryParams[key];
        break;
    }

    // remove null values
    if (value) {
      result[key] = value;
    }

    return result;
  }, {});

  return new Promise((resolve, reject) => {
    Vue.$http
      .post(`api/pacs/query/${modality.tag}`, null, {
        query: queryParams
      })
      .then(resp => resolve(resp.body))
      .catch(error => reject(getResponseError(error)));
  });
};

export const retrieve = (modality, item) => {
  return new Promise((resolve, reject) => {
    Vue.$http
      .post(`api/pacs/retrieve`, null, {
        study_uuid: item.StudyInstanceUID,
        answer_id: item.index,
        query_id: item.query_id
      })
      .then(resp => resolve(resp.body))
      .catch(error => reject(error));
  });
};

export const store = (modality, body) => {
  return new Promise((resolve, reject) => {
    Vue.$http
      .post(`api/pacs/store/${modality.tag}`, null, body)
      .then(resp => resolve(resp.body))
      .catch(error => reject(getResponseError(error)));
  });
};
