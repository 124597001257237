import { BehaviorSubject } from "rxjs";
let ws = {};
let onCloseListener, onErrorListener;

const URL =
  window.location.protocol == "https:"
    ? `wss:/${window.location.host}/ws/series/upload/`
    : `ws:/${window.location.host}/ws/series/upload/`;

export function openSeriesWebsocket(userID, onWsClose, onWsError) {
  const onClose = event => {
    console.warn("WS", event.type, event);
    wsStatus.next(ws.readyState);
    onWsClose(event);
  };

  const onError = event => {
    console.warn("WS", event.type, event);
    if (ws.readyState == WebSocket.OPEN) {
      onWsError(event);
    }
  };

  ws = new WebSocket(URL + userID + `/`);
  wsStatus.next(ws.readyState);

  ws.onopen = () => {
    console.warn("Connected to WebSocket");
    wsStatus.next(ws.readyState);
  };

  onCloseListener = event => onClose(event);
  onErrorListener = event => onError(event);

  ws.onclose = onCloseListener;
  ws.onerror = onErrorListener;

  ws.onmessage = event => {
    console.log(event.data);
  };
}

export function setOnMessage(onmessage) {
  ws.onmessage = onmessage;
}
export function setOnError(onerror) {
  ws.onerror = onerror;
}
const wsStatus = new BehaviorSubject();

export const statusSource = wsStatus;

export const send = message => ws.send(message);
export const disconnect = () => {
  if (ws && typeof ws.close === "function") {
    ws.close();
  }
};
