// DICOM metadata dictionary

export default {
  AccessionNumber: "x00080050",
  AcquisitionDate: "x00080022",
  AcquisitionTime: "x00080032",
  Modality: "x00080060",
  ModalitiesInStudy: "x00080061",
  PatientBirthDate: "x00100030",
  PatientID: "x00100020",
  PatientName: "x00100010",
  PatientSex: "x00100040",
  PixelSpacing: "x00280030",
  SliceThickness: "x00180050",
  SeriesDate: "x00080021",
  SeriesDescription: "x0008103e",
  SeriesInstanceUID: "x0020000e",
  SeriesNumber: "x00200011",
  SeriesTime: "x00080031",
  StudyDate: "x00080020",
  StudyDescription: "x00081030",
  StudyInstanceUID: "x0020000d",
  StudyTime: "x00080030",
  Rows: "x00280010",
  Cols: "x00280011",
  BitsAllocated: "x00280100",
  PixelRepresentation: "x00280103",
  NumberOfTemporalPositions: "x00200105",
  ReferringPhysicianName: "x00080090",
  NameOfPhysiciansReadingStudy: "x00081060"
};
